import { device } from "src/styles/breakpoints"
import styled from "styled-components"
import * as oldColors from 'src/styles/colors'
import * as newColors from 'src/styles/newColors'

export const ConcorraA1MilhaoDePontosLoopimportSection = styled.section`
  padding: 0 0 40px 0;
  overflow-x: hidden;

  background-color: ${oldColors.grayscale[500]};
  background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/background-v2/image.webp);
  background-position: center center;
  background-repeat: no-repeat;

  @media ${device.tablet} {
    background-size: 100% 90%;
    
    padding: 48px 0;
  }

  @media ${device.desktopLG} {

  }

  @media ${device.desktopXXL} {
    padding: 96px 0;
    background-size: 102% 100%;
  }

`

export const Container = styled.div`

  @media ${device.tablet} {

  }

  @media ${device.desktopLG} {

  }

  @media ${device.desktopXXL} {

  }

`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: white;

  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;
  }

  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 53px;
  }

  @media ${device.desktopXXL} {
    font-size: 56px;
    line-height: 62px;
  }

`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .enjoy_now_button {
    margin-top: 40px;
    width: 100%;

    &:hover {
      background-color: ${newColors.primary[400]};
    }

    @media ${device.tablet} {
      width: 456px;
    }

    @media ${device.desktopXXL} {
      width: 552px;
    }
  }
`

export const HighlightedTitle = styled.span`
  color: ${newColors.orange.light};
`

export const Description = styled.p`
  font-family: Inter, sans-serif, arial;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: white;

  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 80px;
  }

  @media ${device.desktopLG} {
    font-size: 20px;
    line-height: 24px;

    margin-bottom: 77px;
  }

  @media ${device.desktopXXL} {
    font-size: 24px;
    line-height: 29px;
  
    width: 67%;
    margin-bottom: 40px;
  }

`

export const RecebaNumerosDaSorte = styled.div`
  width: 312px;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    width: 65vw;
  }

  @media ${device.desktopXXL} {
    width: 64vw;
  }

`

export const RecebaNumerosDaSorteImage = styled.img`
  width: 100%;
  height: auto;
`

export const MoedaPromocaoMilhao = styled.img`
  position: absolute;
  width: 125px;
  top: -36px;
  right: -48px;

  @media ${device.tablet} {
    width: 24vw;
    top: -5vw;
    right: -11vw;
  }

  @media ${device.desktopXXL} {
    width: 22vw;
  }

`
