import React, { useState } from 'react'

import * as S from './style'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

function Duvidas () {
  const [ collapsed, setCollapse ] = useState(true)

  return (
    <S.Wrapper
      id='tudo-sobre-a-black-friday'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.Title>
              Tudo sobre a Black Friday
            </S.Title>
          </div>
          <div className='col-12'>
            <S.CollapsableContent collapsed={collapsed}>
              <S.Question>
                O que é Black Friday?
              </S.Question>
              <p>
                Black Friday é um tradicional dia de promoções e ofertas que surgiu nos Estados Unidos e se popularizou em todo mundo.
                <br /><br />
                Esta é a melhor época do ano para fazer compras, pois você aproveita uma série de descontos em produtos como eletrodomésticos, smartphones, games, eletroportáteis, móveis, vestuário, cosméticos e presentes em diversas lojas do Brasil.
              </p>

              <S.Question>
                Quando é a Black Friday 2024?
              </S.Question>
              <p>
                A Black Friday 2024 será no dia 29 de novembro. Isso porque ela acontece sempre na 4º sexta-feira do mês de novembro, após o Dia de Ação de Graças nos Estados Unidos, e marca a abertura da temporada de compras de Natal.
                <br /><br />
                Mas apesar de ter uma data específica para a Black Friday, muitas marcas e lojas antecipam os melhores descontos e ofertas promovendo vários dias de condições especiais. A data se encerra com a realização da Cyber Monday, “Segunda-Feira Cibernética” em português, um evento de descontos exclusivamente das lojas online.
              </p>

              <S.Question>
                Como economizar na Black Friday?
              </S.Question>
              <p>
                Pra aproveitar as melhores ofertas da Black Friday 2024, não deixe de seguir algumas dicas:
                <br /><br />
                <ol type='1' start={1}>
                  <li>
                    Compare preços
                  </li>
                  <br />

                  <li>
                    Leia sobre os produtos que deseja comprar na Black Friday
                  </li>
                  <br />

                  <li>
                    Confira o prazo de entrega, condições de parcelamento e descontos da Black Friday 2024
                  </li>
                  <br />

                  <li>
                    Certifique-se de comprar em sites confiáveis
                  </li>
                  <br />

                  <li>
                    Dê preferência para as lojas que ofereçam, além do desconto, cashback nas compras
                  </li>
                  <br />

                  <li>
                    Aproveite cupons de desconto e muito cashback, no Shopping do Inter. Nele, você compra com preço de Black Friday e aproveita condições imperdíveis nas lojas e marcas que mais gosta: smartphones, celulares, notebooks, TVs, eletroportáteis, móveis e muito mais.
                  </li>
                </ol>
              </p>

              <S.Question>
                Ofertas Black Friday: onde encontrar as melhores?
              </S.Question>
              <p>
                Pra sair na frente no dia da Black Friday a dica é cadastrar na Lista VIP da Orange Friday. Assim, você vai receber, em primeira mão, ofertas relâmpago, cupons de desconto exclusivos, melhores preços e condições do mercado nos seus produtos favoritos. Então, se você ficou esperando o ano inteiro pra comprar iPhone na Black Friday, por exemplo, garanta o seu com o melhor preço! Pra isso, você também pode aproveitar o Alerta de Preço Baixo no Shopping do Inter. Você sugere quanto quer pagar e a gente te avisa quando encontrarmos o melhor preço do mercado. Demais né?
              </p>
            </S.CollapsableContent>
          </div>
          <div className='col-12 text-center mt-4'>
            {
              !collapsed ? (
                <button onClick={() => setCollapse(!collapsed)} style={{ background: 'none' }} className='text-orange--extra fw-600'>
                  Mostrar menos
                  <OrangeDsIcon icon='chevron-up' size='SM' color='#FF7A00' />
                </button>
              ) : (
                <button onClick={() => setCollapse(!collapsed)} style={{ background: 'none' }} className='text-orange--extra fw-600'>
                  Mostrar mais
                  <OrangeDsIcon icon='chevron-down' size='SM' color='#FF7A00' />
                </button>
              )
            }
          </div>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default Duvidas
