import React from 'react'

import * as S from './_styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const LojasEMarcasMaisDesejadas = () => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.LojasEMarcasMaisDesejadasSection
      id='lojas-e-marcas-mais-desejadas'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.Title>
              Lojas e marcas mais desejadas da Black Friday 2024
            </S.Title>
            <S.Description>
              Compre em mais de 600 lojas e marcas pelo Shopping do Inter e ganhe cashback direto na sua conta.
            </S.Description>
            <a
              href='https://inter-co.onelink.me/Qyu7/v77fi0z7'
              target='_blank'
              rel='noreferrer'
              onClick={() => sendDataLayer({
                section: 'dobra_06',
                section_name: 'Lojas e marcas mais desejadas da Black Friday 2024',
                element_action: 'click banner',
                element_name: 'Lojas e marcas mais desejadas da Black Friday 2024',
                redirect_url: 'https://inter-co.onelink.me/Qyu7/v77fi0z7',
              })}
            >
              <S.BrandsAndStores>
                <source
                  srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/bf-marcas-web/image.webp'
                  media='(min-width: 768px)'
                />
                <S.BrandsAndStoresSource
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/bf-marcas-mobile/image.webp'
                  alt='Lojas e marcas mais desejadas da Black Friday 2024'
                />
              </S.BrandsAndStores>
            </a>
          </div>
        </div>
      </div>
    </S.LojasEMarcasMaisDesejadasSection>
  )
}

export default LojasEMarcasMaisDesejadas
