import React from 'react'
import * as S from './_styles'
import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const BlackFridayParaEmpresas = () => {
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.BlackFridayParaEmpresasSection
      id='black-friday-para-empresas'
    >
      <div className='container'>
        <S.Row className='row'>
          <S.TextsWrapper className='col-12 col-md-6'>
            <S.Title>
              <S.HightlightedTitle>Black Friday<br />para empresas?</S.HightlightedTitle>
              No Inter tem.
            </S.Title>
            <S.Description>
              Ofertas em Capital de Giro, Maquininha de Cartão, Investimentos e, claro, Shopping!
              <br /><br />
              Se você ainda não é correntista, abra sua conta PJ e MEI e aproveite todas as vantagens.
              <br /><br />
              É correntista? Corra pro Shopping do Inter Empresas e ative seu alerta de preço para os produtos mais desejados.
            </S.Description>

            <Button
              fullWidth
              as='a'
              target='_blank'
              rel='noreferrer'
              href='https://lp.inter.co/oferta-acesso-app-pj'
              className='access_shopping_button'
              variant='primary'
              onClick={() => sendDataLayer({
                section: 'dobra_07',
                section_name: 'Black Friday para empresas? No inter tem',
                element_action: 'click button',
                element_name: 'Acessar o Shopping PJ',
                redirect_url: 'https://lp.inter.co/oferta-acesso-app-pj',
              })}
            >
              Acessar o Shopping PJ
            </Button>
            <Button
              fullWidth
              as='a'
              target='_blank'
              rel='noreferrer'
              href='http://conta-digital-pj.inter.co/login?&'
              className='open_account_enterprise_button'
              variant='secondary'
              onClick={() => sendDataLayer({
                section: 'dobra_07',
                section_name: 'Black Friday para empresas? No inter tem',
                element_action: 'click button',
                element_name: 'Abrir conta Inter Empresas',
                redirect_url: 'http://conta-digital-pj.inter.co/login?&',
              })}
            >
              Abrir conta Inter Empresas
            </Button>
          </S.TextsWrapper>

          <div className='col-12 col-md-6'>
            <S.SideImage
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/black-friday-dobra7/image.webp'
            />
          </div>
        </S.Row>
      </div>
    </S.BlackFridayParaEmpresasSection>
  )
}

export default BlackFridayParaEmpresas
