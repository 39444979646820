import React from 'react'
import * as S from './_styles'
import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const ConcorraA1MilhaoDePontosLoop = () => {
  const [ sendDataLayer ] = useDataLayer()
  const comprarEConcorrerDeepLink = 'https://inter-co.onelink.me/Qyu7/5zl2r0d0'

  return (
    <S.ConcorraA1MilhaoDePontosLoopimportSection
      id='concorra-a-1-milhao-de-pontos-loop'
    >
      <S.Container className='container'>
        <S.Row className='row'>
          <S.Content className='col-12'>
            <S.Title>
              Concorra a <S.HighlightedTitle>1 MILHÃO</S.HighlightedTitle> de pontos Loop
            </S.Title>
            <S.Description>
              Aproveite as ofertas da Orange Friday e ganhe números da sorte pra concorrer a 1 milhão de pontos Loop.
            </S.Description>

            <S.RecebaNumerosDaSorte>
              <S.RecebaNumerosDaSorteImage
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/orange-dobra2-tabela/image.webp'
              />
              <S.MoedaPromocaoMilhao
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/orange-dobra2-selo/image.webp'
              />
            </S.RecebaNumerosDaSorte>

            <Button
              className='enjoy_now_button'
              as='a'
              href={comprarEConcorrerDeepLink}
              target='_blank'
              onClick={() => sendDataLayer({
                section: 'dobra_02',
                section_name: 'concorra_a_1_milhao_de_pontos_loop',
                element_action: 'click button',
                element_name: 'Comprar e concorrer',
                redirect_url: comprarEConcorrerDeepLink,
              })}
            >
              Comprar e concorrer
            </Button>
          </S.Content>
        </S.Row>
      </S.Container>
    </S.ConcorraA1MilhaoDePontosLoopimportSection>
  )
}

export default ConcorraA1MilhaoDePontosLoop
