import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import { Button } from '@interco/inter-ui/components/Button'
import useDomReady from 'src/hooks/window/useDomReady'
import * as S from './styles'

function Hero () {
  const width = useWidth()
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  const buttonDeepLink = 'https://inter-co.onelink.me/Qyu7/5zl2r0d0'

  const CashbackText = () => (
    <S.Cashback.wrapper>
      <S.Cashback.text.sm>Até{' '}</S.Cashback.text.sm>
      <S.Cashback.text.lg highlighted>70%{' '}</S.Cashback.text.lg>
      <S.Cashback.text.lg>OFF</S.Cashback.text.lg>
      <br />
      <S.Cashback.text.xl>+</S.Cashback.text.xl>
      <S.Cashback.text.sm>Até{' '}</S.Cashback.text.sm>
      <S.Cashback.text.lg highlighted>30%{' '}</S.Cashback.text.lg>
      <S.Cashback.text.md>cashback</S.Cashback.text.md>
    </S.Cashback.wrapper>
  )

  const orangeFridayImage = domReady && (
    <S.OrangeFridayImage
      src='https://central-imagens.bancointer.com.br/images-without-small-versions/black-friday-hero24/image.webp'
      alt='Tatá Werneck sorrindo enquanto usa o celular pra comprar na Orange Friday 2024. Aproveite as ofertas e concorra a 1 milhão de pontos Loop'
    />
  )

  return (
    <S.Section
      id='orange-friday-a-black-friday-do-inter'
    >
      { width >= 768 && orangeFridayImage }
      <div className='container'>
        <S.Row className='row'>
          <S.CountdownWrapper className='col-12 col-md-6' />

          <S.Texts className='col-12 col-md-6 col-xl-6'>
            <S.Title>
              <S.TitleHighlighted>Orange Friday</S.TitleHighlighted>, a Black Friday do Inter
            </S.Title>
            <S.Subtitle>
              <strong>Comprar muito e pagar pouco</strong>: você não sabia que precisava até a Orange Friday.
            </S.Subtitle>
            <CashbackText />
            <Button
              className='enjoy_now_button'
              fullWidth
              as='a'
              href={buttonDeepLink}
              target='blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Orange Friday, a Black Friday do Inter',
                  element_action: 'click button',
                  element_name: 'Comprar agora',
                  redirect_url: buttonDeepLink,
                })
              }}
            >
              Aproveitar agora
            </Button>
          </S.Texts>
        </S.Row>
      </div>
      { width < 768 && orangeFridayImage }
    </S.Section>
  )
}

export default Hero
