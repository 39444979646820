import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './styles'
import pageContext from './pageContext.json'
import { initTheme, Theme } from '@interco/inter-ui'

import S1_OrangeFridayABlackFridayDoInter from './sections/S1-orange-friday-a-black-friday-do-inter/_index'
import S2_ConcorraA1MilhaoDePontosLoop from './sections/S2-concorra-a-1-milhao-de-pontos-loop/_index'
import S3_AsMelhoresOfertas from './sections/S3-as-melhores-ofertas/_index'
import S4_TudoPraVoceAproveitarABlackFriday from './sections/S4-tudo-pra-voce-aproveitar-a-black-friday-2024/_index'
import S5_AproveiteNaOrangeFriday from './sections/S5-aproveite-na-orange-friday/_index'
import S6_LojasEMarcasMaisDesejadas from './sections/S6-lojas-e-marcas-mais-desejadas/_index'
import S7_BlackFridayParaEmpresas from './sections/S7-black-friday-para-empresas/_index'
import S8_DicasParaAproveitarABlackFriday from './sections/S8-dicas-para-aproveitar-a-black-friday/_index'
import S9_EsquentaBlackFriday from './sections/S9-esquenta-black-friday/_index'
import S10_TudoSobreABlackFriday from './sections/S10-tudo-sobre-a-black-friday/_index'
import S11_FAQ from './sections/S11-faq/_FAQ'

const BlackFriday = () => {
  React.useEffect(() => {
    initTheme(Theme.PF)
  })

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <S1_OrangeFridayABlackFridayDoInter />
        <S2_ConcorraA1MilhaoDePontosLoop />
        <S3_AsMelhoresOfertas />
        <S4_TudoPraVoceAproveitarABlackFriday />
        <S5_AproveiteNaOrangeFriday />
        <S6_LojasEMarcasMaisDesejadas />
        <S7_BlackFridayParaEmpresas />
        <S8_DicasParaAproveitarABlackFriday />
        <S9_EsquentaBlackFriday />
        <S10_TudoSobreABlackFriday />
        <S11_FAQ faqData={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}
export default BlackFriday
