import styled from 'styled-components'
import * as oldColors from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const LojasEMarcasMaisDesejadasSection = styled.section`
  background-color: ${oldColors.grayscale[600]};
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 48px 0 47px 0;
  }

  @media ${device.desktopLG} {
    padding: 48px 0;
  }
  
  @media ${device.desktopXXL} {
    padding: 96px 0 93px 0;
  }
`

export const Title = styled.h2`
  font-family: Citrina , sans-serif, Arial;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: white;
  text-align: center;
  
  margin: 0 auto 16px auto;
  
  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 53px;
    
    width: 70%;
  }

  @media ${device.desktopXXL} {
    font-size: 56px;
    line-height: 62px;

    width: 80%;
  }
`

export const Description = styled.p`
  font-family: Inter, sans-serif, Arial;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
  text-align: center;

  margin: 0 auto 24px auto;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
  
    width: 70%;
    margin-bottom: 40px;
  }

  @media ${device.desktopLG} {
    width: 100%;
  }

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;
  
    margin-bottom: 60px;
  }
`

export const BrandsAndStores = styled.picture`
  display: flex;
`

export const BrandsAndStoresSource = styled.img`
  width: 100%;
`
