import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

type CollapsableContentProps = {
  collapsed: boolean;
}

export const Wrapper = styled.div`
  background-color: ${grayscale[500]};
  padding: 40px 0;
  overflow: hidden;

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  p {
    font-family: Inter, sans-serif, Arial;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: white;

    ol {
      padding-left: 40px;
    }

    margin-bottom: 40px;

    @media ${device.desktopXXL} {
      font-size: 18px;
      line-height: 27px;
    }
  }
`
export const CollapsableContent = styled.div<CollapsableContentProps>`
  transition: all 0.5s ease-in-out ;
  overflow: hidden;
  
  height: ${({ collapsed }: CollapsableContentProps) => collapsed ? '300px' : 'fit-content'};
  max-width: 744px;
  margin: 0 auto;
`

export const Question = styled.h3`
  font-family: Inter, sans-serif, Arial;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: white;

  @media ${device.desktopXXL} {
    font-size: 24px;
    line-height: 29px;
  }
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: white;

  margin: 0 auto;
  margin-bottom: 32px;
  max-width: 744px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 38px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 40px;
  }

  @media ${device.desktopXXL} {
    font-size: 56px;
    line-height: 62px;
    margin-bottom: 60px;
  }
`
