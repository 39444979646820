import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './styles'
import * as T from './_types'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import produtosJSON from './data.json'
import { Button } from '@interco/inter-ui/components/Button'

const EverythingForYouToEnjoy = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Section
      id='tudo-pra-voce-aproveitar-a-black-friday-2024'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.Title className='col-12'>
              Tudo pra você aproveitar a Black Friday 2024 em um só lugar
            </S.Title>
            <S.Description>
              <strong>Ofertas por tempo limitado</strong>, consulte prazos e condições direto no Super App.
            </S.Description>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 140 }}
              md={{ items: 4 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
              autoPlay
              infinite
              notShowArrows
              notShowDots
            >
              {
                produtosJSON.map((CardsContent: T.CardsContent) => (
                  <S.CardColumn key={CardsContent.id}>
                    {CardsContent.items.map((item: T.Cards) => (
                      <S.Card key={item.title}>
                        <S.CardImage
                          src={item.img}
                          alt={item.alt}
                        />
                        <S.CardLabel
                          color={item.cardLabel.color as T.LabelColors}
                        >
                          {item.cardLabel.description}
                        </S.CardLabel>
                        <S.CardTextsWrapper>
                          <S.CardTitle
                            dangerouslySetInnerHTML={{ __html: item.title }}
                          />
                          <S.CardDescription
                            dangerouslySetInnerHTML={{ __html: item.description }}
                          />
                          <Button
                            fullWidth
                            as='a'
                            href={item.link} target='__blank'
                            rel='noreferrer'
                            className='enjoy_button'
                            onClick={() => {
                              sendDatalayerEvent({
                                 section: 'dobra_04',
                                section_name: 'Tudo pra você aproveitar a Black Friday 2024 em um só lugar',
                                element_action: 'click banner',
                                element_name: 'Aproveitar +' + item.title,
                                redirect_url: item.link,
                              })
                            }}
                          >
                            Aproveitar
                          </Button>
                        </S.CardTextsWrapper>
                      </S.Card>
                  ))}
                  </S.CardColumn>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default EverythingForYouToEnjoy
