import React from 'react'
import * as S from './style'
import data from './assets/data.json'
import image01 from './assets/black-friday-blog-01.png'
import image02 from './assets/black-friday-blog-02.png'
import image03 from './assets/black-friday-blog-03.png'
import image04 from './assets/black-friday-blog-04.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OpenVideo from 'src/components/OpenVideo'

function Blog () {
  const [ sendDatalayerEvent ] = useDataLayer()

  const imageMap = {
    image01,
    image02,
    image03,
    image04,
  }

  return (
    <S.Section
      id='dicas-para-aproveitar-a-black-friday'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <S.Title>
              Dicas pra aproveitar a Black Friday 2024
            </S.Title>
            <S.Description>
              <strong>A Orange Friday é a Black Friday do Inter</strong> e, além de aproveitar as melhores ofertas do mercado, por aqui a gente também te ajuda a escolher os melhores produtos e a comprar de forma segura.
            </S.Description>
          </div>
          {
            data.map((item: {link: string; img: string; title: string}) => (
              <div className='col-12 col-md-6 col-xl-3' key={item.title}>
                <a
                  href={item.link}
                  className='d-flex flex-column align-items-center'
                  onClick={() => sendDatalayerEvent({
                    section: 'dobra_7',
                    element_action: 'click button',
                    element_name: item.title,
                    section_name: 'Dicas pra aproveitar a Black Friday 2024',
                    redirect_url: item.link,
                    })}
                >
                  <img
                    src={imageMap[item.img as keyof typeof imageMap]}
                    width={250}
                    height={141}
                    alt=''
                  />
                  <S.CardTitle>
                    {item.title}
                  </S.CardTitle>
                </a>
              </div>
            ))
          }
          <S.VideoContainer className='col-12'>
            <OpenVideo link='https://www.youtube.com/embed/PZ_okxxgCjg?autoplay=1'>
              <S.ThumbNail
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/video-black-friday-2024/image.webp'
                alt=''
              />
            </OpenVideo>
          </S.VideoContainer>
        </div>
      </div>
    </S.Section>
  )
}

export default Blog
