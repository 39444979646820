import styled from 'styled-components'
import { grayscale, green, orange, red } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'

export const Section = styled.section`
  background-color: ${orange.extra};
  overflow: hidden;
  z-index: 1;
  position: relative;

  padding: 40px 0;
  @media ${device.tablet} {
    padding: 40px 0;
  }

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }
`

export const Card = styled.div`
  width: 95%;
  margin-right: 15px;
  border-radius: 12px;
  background-color: ${grayscale[500]};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .release_offers_button {
    &:hover {
      background-color: ${primary[400]};
    }
  }
`

export const CardImg = styled.div`
  width: 100%;
  background-color: white;
  padding: 15px;
`

export const CardText = styled.h3`
  font-family: 'Inter' !important;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
`

export const CardLabels = styled.div`
  margin-top: -15px;
  margin-left: 16px;

  .discount {
    background-color: ${green[400]};
    margin-bottom: 5px !important;
    font-weight: 700;
    padding: 0 8px;
    border-radius: 18px;  
    width: fit-content;
  }

  .stock {
    background-color: ${red.base};
    padding: 0 8px;
    border-radius: 18px;
    width: fit-content;
    font-weight: 700;
  }
`

export const CardPrice = styled.span`
  opacity: 0.8;
  filter: blur(2.5px);
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: white;
  text-align: center;

  margin-bottom: 16px;
  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;
  }

  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 53px;
  }

  @media ${device.desktopXXL} {
    font-size: 56px;
    line-height: 62px;
  }
`

export const Subtitle = styled.p`
  font-family: Inter, sans-serif, Arial;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px; 
  color: white;
  text-align: center;
  
  margin-bottom: 10px; 

  @media ${device.tablet} {
    width: 90%;
    margin: 0 auto;
  }

  @media ${device.desktopLG} {
    width: 80%;
    font-size: 20px;
    line-height: 24px;     
  }

  @media ${device.desktopXXL} {
    width: 90%;
    font-size: 18px;
    line-height: 22px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media ${device.desktopXXL} {
    width: 75%;
  }
`
