import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './styles'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import produtosJSON from './data.json'
import { ImgWebp } from 'src/styles/imgWebp'
import { Button } from '@interco/inter-ui/components/Button'

type saveEvenMoreProps = {
  title: string;
  link: string;
  img: string;
  redirect: string;
}

const AsMelhoresOfertas = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Section
      id='as-melhores-ofertas'
    >
      <div className='container'>
        <div className='row'>
          <S.TitleWrapper>
            <S.Title>
              As melhores ofertas da Black Friday estão no Shopping do Inter
            </S.Title>
            <S.Subtitle>
              <strong>A Orange Friday 2024 será no dia 29 de novembro.</strong> Mas já tem oferta relâmpago rolando no Shopping do Inter. Vem aproveitar:
            </S.Subtitle>
          </S.TitleWrapper>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              md={{ items: 2, partialVisibilityGutter: 20 }}
              lg={{ items: 3, partialVisibilityGutter: 20 }}
              xl={{ items: 4, partialVisibilityGutter: 20 }}
              autoPlay
              infinite
              customColor='white'
            >
              {
                produtosJSON.map((item: saveEvenMoreProps) => (
                  <S.Card key={item.title}>
                    <S.CardText className='fs-18 lh-30 text-white mb-0'>{item.title}</S.CardText>
                    <S.CardImg>
                      <ImgWebp
                        src={item.img}
                        breakPointsSize={{ sm: '100%' }}
                      />
                    </S.CardImg>
                    <S.CardLabels>
                      <p className='discount mb-0 fs-14 fw-700 text-white'>
                        Até 60% OFF
                      </p>
                      <p className='stock mb-0 fs-14 fw-700 text-white'>
                        Estoque Limitado
                      </p>
                    </S.CardLabels>
                    <div className='ml-3 mt-3'>
                      <p className='text-white fs-14 mb-0' style={{ fontWeight: 700 }}>De: <S.CardPrice>R$ 8.057,00</S.CardPrice></p>
                      <p className='text-white fs-20 mb-0' style={{ fontWeight: 700 }}>Por: <S.CardPrice>R$ 6.846,89</S.CardPrice></p>
                    </div>
                    <div className='px-3 py-3'>
                      <Button
                        fullWidth
                        as='a'
                        href={item.link}
                        target='__blank'
                        rel='noreferrer'
                        className='release_offers_button'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            section_name: 'As melhores ofertas da Black Friday estão no Shopping do Inter',
                            element_action: 'click banner',
                            element_name: 'Liberar ofertas + ' + item.title,
                            redirect_url: item.redirect,
                          })
                        }}
                      >Liberar ofertas
                      </Button>
                    </div>
                  </S.Card>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default AsMelhoresOfertas
