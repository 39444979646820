import { device } from 'src/styles/breakpoints'
import * as newColors from 'src/styles/newColors'
import styled from 'styled-components'

export const AproveiteNaOrangeFridaySection = styled.section`
  padding: 40px 0 46px 0;
  background-color: ${newColors.graphiteOne};

  @media ${device.tablet} {
    padding: 48px 0;
  }

  @media ${device.desktopXXL} {
    padding: 96px 0;
  }
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, Arial;
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: white;

  margin-bottom: 32px;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;

    margin-bottom: 80px;
  }

  @media ${device.desktopLG} {
    font-size: 56px;
    line-height: 62px;
  }

  @media ${device.desktopXXL} {
    margin-bottom: 60px;
  }
`

export const Advantages = styled.picture`
  display: flex;
  justify-content: center;
  align-items: center;

`

export const AdvantagesSource = styled.img`
  width: 100%;

  @media ${device.tablet} {
    width: 90%;
  }

  @media ${device.desktopLG} {
    width: 89%;
  }

  @media ${device.desktopXXL} {
    width: 1151px;
  }
`
