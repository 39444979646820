import React from 'react'
import linksJSON from './data.json'
import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type Props = {
  text: string;
  link: string;
}

function WarmUpBlackFriday () {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Wrapper
      id='esquenta-black-friday'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <S.Title>
              Esquenta Black Friday
            </S.Title>
            <S.Description>
              Ansioso pro <strong>dia da Black Friday</strong>? Aqui tem ofertas quentes pra você, vem!
            </S.Description>
          </div>
          <S.PromotionProducts className='col-12'>
            {
            linksJSON.map((item: Props) => (
              <div key={item.text}>
                <a
                  href={item.link}
                  target='blank'
                  className='text-white fs-14'
                  role='button'
                  aria-label={item.text}
                  onClick={() => sendDatalayerEvent({
                    section: 'dobra_08',
                    element_action: 'click button',
                    element_name: item.text,
                    section_name: 'Esquenta Black Friday',
                    redirect_url: item.link,
                   })}
                >
                  {item.text}
                </a>
              </div>
            ))
          }
          </S.PromotionProducts>
        </div>
      </div>
    </S.Wrapper>
  )
}

export default WarmUpBlackFriday
