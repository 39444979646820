import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as S from './_styles'

const AproveiteNaOrangeFriday = () => {
  const interShopLink = 'https://inter-co.onelink.me/Qyu7/5zl2r0d0'
  const [ sendDataLayer ] = useDataLayer()

  return (
    <S.AproveiteNaOrangeFridaySection
      id='aproveite-na-orange-friday'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.Title>
              Aproveite na Orange Friday
            </S.Title>

            <a
              href={interShopLink}
              rel='noreferrer'
              target='_blank'
              onClick={() => sendDataLayer({
                section: 'dobra_05',
                section_name: 'Aproveite na Orange Friday',
                element_action: 'click banner',
                element_name: 'Image - Mulher sentada em poltrona usando o celular e sorrindo',
                redirect_url: interShopLink,
              })}
            >
              <S.Advantages>
                <source
                  srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/black-friday-dobra5-web/image.webp'
                  media='(min-width: 768px)'
                />
                <S.AdvantagesSource
                  src='https://central-imagens.bancointer.com.br/images-without-small-versions/black-friday-dobra5-mobile/image.webp'
                  alt='Mulher sentada em poltrona usando o celular e sorrindo'
                />
              </S.Advantages>
            </a>
          </div>
        </div>
      </div>
    </S.AproveiteNaOrangeFridaySection>
  )
}

export default AproveiteNaOrangeFriday
