import styled, { css } from 'styled-components'
import * as oldColors from 'src/styles/colors'
import * as newColors from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

export const BlackFridayParaEmpresasSection = styled.section`
  background-color: ${newColors.orange.dark};

  padding: 40px 0;

  @media ${device.tablet} {
    padding: 56px 0;
  }
  @media ${device.desktopLG} {
    padding: 64px 0;
  }
  @media ${device.desktopXXL} {
    padding: 96px 0;
  }

`

const TitleStyles = css`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-align: left;

  @media ${device.tablet} {
    font-size: 48px;
    line-height: 53px;
  }
  
  @media ${device.desktopLG} {
    font-size: 56px;
    line-height: 62px;
  }
`

export const Title = styled.h2`
  ${TitleStyles}
  color: white;

  margin-bottom: 16px;
`

export const HightlightedTitle = styled.span`
  ${TitleStyles}
  color: ${oldColors.orange[500]};
  display: block;
`

export const Description = styled.p`
  font-family: Inter, sans-serif, arial;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: white;

  margin-bottom: 32px;

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const SideImage = styled.img`
  width: 100%;
`

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .access_shopping_button {
    margin-bottom: 16px;
  }

  .access_shopping_button, .open_account_enterprise_button {
    &:hover {
      background-color: ${newColors.primary[400]};
      border: none;
    }

    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXXL} {
      max-width: 455px;
    }
  }

`

export const ImageWrapper = styled.div``

export const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media ${device.tablet} {
    flex-direction: row;
    gap: 0;
  }
`
