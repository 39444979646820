import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  padding: 40px 0;
  background-color: ${grayscale[500]};


  @media ${device.tablet} {
    padding: 40px 0;
  }

  @media ${device.desktopLG} {
    padding: 80px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  img {
    border-radius: 8px;
  }

`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: white;
  
  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 38px;
  }

  @media ${device.desktopXXL} {
    font-size: 56px;
    line-height: 62px;
  }
  
`

export const Description = styled.p`
  font-family: Inter, sans-serif, Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: white;

  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-bottom: 16px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 40px;
  }

  @media ${device.desktopXXL} {
    margin-bottom: 60px;
  }
`

export const CardTitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: Inter, sans-serif, Arial;
  letter-spacing: 0.3px;
  color: white;
`

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ThumbNail = styled.img`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 458px;
  height: auto;

  @media ${device.desktopXXL} {
    max-width: 552px;
  }
`
